import React, { Suspense, lazy } from 'react';
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
const Slogan = lazy(() => import('sections/hero/HeroWemake.js'));
const Vasco = lazy(() => import('sections/hero/Hero.js'));
const CloudHosting = lazy(() => import('sections/services/CloudHosting.js'));
const CloudHosting2 = lazy(() => import('sections/services/CloudHosting2.js'));
const SslGratis = lazy(() => import('sections/services/SslGratis.js'));
const SSLSan = lazy(() => import('sections/about/SSLSan.js'));
const SSLBlancoInicio = lazy(() => import('sections/about/SSLsanBlancoInicio.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const DeveloperWeb = lazy(() => import('sections/about/DeveloperWeb.js'));
const DeveloperMobile = lazy(() => import('sections/about/DeveloperMobile.js'));
const WebDesign = lazy(() => import('sections/about/WebDesign.js'));
const Conecta = lazy(() => import('sections/hero/Conecta.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const AsesoriaGratis = lazy(() => import('sections/hero/AsesoriaGratis.js'));
const OffShore = lazy(() => import('sections/about/OffShore.js'));
const OtrosServicios = lazy(() => import('sections/services/OtrosServicios.js'));
const TestimonialsThree = lazy(() => import('sections/testimonials/TestimonialsThree.js'));
const ClientsOne = lazy(() => import('sections/clients/ClientsOne.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('./../sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    // Datos estructurados para Organización
    const Organizacion = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "VASCO Solutions",
      "url": "https://solucionesenlaweb.com",
      "logo": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
      "address": [{
        "@type": "PostalAddress",
        "streetAddress": "Coral Gable",
        "addressLocality": "Miami",
        "addressRegion": "Florida",
        "postalCode": "33143",
        "addressCountry": "US"
      }, {
        "@type": "PostalAddress",
        "streetAddress": "Santa Rosa de Cabal",
        "addressLocality": "Santa Rosa de Cabal",
        "addressRegion": "Risaralda",
        "postalCode": "661020",
        "addressCountry": "CO"
      }
      ],
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "contactType": "Customer Service",
          "telephone": "+1-954-603-4747",
          "email": "ventas@vascosolutions.com",
        },
        {
          "@type": "ContactPoint",
          "contactType": "Departamento de Ventas",
          "telephone": "+573102767675",
          "email": "ventas@vascosolutions.com",
        }
      ],
      "sameAs": [
        "https://es-la.facebook.com/VASCOSolutions",
        "https://x.com/VASCOSOLUTIONS"
      ]
    }

    // Datos estructurados para FAQ
    const QA = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "¿Qué es Web Hosting?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Para comenzar diremos que Hosting, significa literalmente alojamiento, y si lo trasladamos a la era de Internet, cuando se habla de “Web Hosting” no es otra cosa que alojamiento web. El alojamiento web propiamente dicho se puede definir como un servicio prestado por un ISP (proveedor), que permite a los usuarios de Internet tener un sistema integrado para poder almacenar información, que permite tener su página Web, archivos y aplicaciones al aire en Internet. Todo esto es montado en un servidor que esta funcionando las 24 horas al día, para que su sitio pueda ser visto en todas partes del mundo. No siempre es necesario alojar páginas webs, sino que también puedes alojar archivos de imágenes, vídeo, documentos, correo electrónico, etc. En otras palabras, es un servicio que le otorga un lugar en la 'nube', para hospedar sus aplicaciones, emails, páginas web y archivos en general. Todos nuestros planes de hosting se encuentran en servidores de alto rendimiento con copias de seguridad diarias. Ofrecemos Hosting en CloudLinux, hosting en Windows, Dedicados y VPS."
        }
      },
      {
        "@type": "Question",
        "name": "¿Qué es Espacio?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "El espacio en disco (disk space) es un término usado en la industria del hospedaje web (web hosting) que se refiere a la cantidad de espacio de datos disponible en el disco duro de un servidor web (ya sea espacio total o el espacio asignado a un usuario en particular). Dicho de otra manera, es el espacio disponible en un servidor para el hospedaje o almacenamiento de un proyecto web y se mide en Megabytes. Por ejemplo, nuestro Plan Personal tiene de espacio en disco 1000 Mb. ¿Cuánto espacio requiero? La cantidad de espacio que un cliente en particular requiere dentro de su cuenta de hospedaje web, varia dependiendo de varios factores, como el tipo de sitio que tiene y las características de la información que maneja. Para entender esto un poco mejor, veamos la siguiente explicación: Todos los datos, contenido e información de un sitio web son almacenados dentro del espacio en disco total que está disponible para un usuario (puede ser todo el disco duro o parte de él), incluyendo: - Archivos - Bases de datos - Imágenes - Scripts - E-mails - Registros del servicio (estadísticas, logs, etc.). Una forma sencilla de no ocupar el espacio en disco con los correos, es utilizar Thunderbird y así guardar los mensajes en su computadora y no utilizar el espacio del servidor."
        }
      },
      {
        "@type": "Question",
        "name": "¿Qué es Transferencia?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "En términos computacionales, el ancho de banda (bandwidth en inglés) suele referirse a la capacidad de transmisión que tiene una vía de comunicación de datos, por ejemplo, el bus de una computadora o un puerto de red. Por otro lado, hablando de hospedaje web, la transferencia de datos (también llamada data transfer en inglés), se refiere a la cantidad total de datos que un sitio web (o conjunto de sitios web) transmiten en un periodo determinado de uso. Es muy importante que el cliente de hospedaje web (web hosting) aprenda a distinguir la diferencia entre “ancho de banda” y “transferencia de datos”. El primero, o ancho de banda, se refiere a capacidad mientras que el segundo, o transferencia de datos, se refiere a cantidad. Así pues, la transferencia de datos en el servidor web, es la cantidad que puede transferir este, desde y hacia el. Este es un recurso limitado y su medición es mensual, por ej. si tiene en su cuenta 2 Gb/mes significa que se permite cada mes un máximo de transferencia de 2 Gb, (pueden enviarse y recibirse datos desde y hacia el servidor por un total de 2 gigabytes). La transferencia mensual se consume a medida que se visita su sitio web, o cuando haces uso de ciertos servicios en el servidor, por ej. cargas/descargas archivos, envías y recibes emails, etc."
        }
      },
      {
        "@type": "Question",
        "name": "¿Qué son los planes multidominios?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Los planes multidominio o con dominios adicionales, son los planes hosting que le permiten tener varios sitios web o varios dominios registrados en la misma cuenta, aparte del dominio principal. Por ejemplo: mi-dominio-principal.com mi-sitio-secundario.com mi-dominio-adicional.com Estos 3 dominios pueden alojarse en la misma cuenta o plan hosting, pero debe tener en cuenta que para ello deben estar registrados los 3 dominios. El primero de ellos seria el dominio principal de la cuenta y los otros 2, serian los dominios adicionales o apuntados en la misma cuenta o plan hosting, si este se lo permite."
        }
      },
      {
        "@type": "Question",
        "name": "¿Qué es cPanel?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "cPanel es un panel de control para administrar servidores de alojamiento web que proveen herramientas de automatización y una interfaz gráfica basada en páginas web. A través de este panel de control es posible instalar aplicaciones, monitorear el rendimiento de las páginas y, también, realizar configuraciones y ediciones de todos los niveles en un entorno. Actualmente, cPanel es el estándar de la industria y la mayoría de los desarrolladores web lo conocen bien. Intuitivo y fácil de usar, cPanel te permite administrar una cuenta de hosting web con la máxima eficiencia."
        }
      },
      {
        "@type": "Question",
        "name": "¿Cuánto cuesta el hosting en Colombia?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Los precios para un hosting en Colombia son relativamente bajos y van desde los $79.997 pesos colombianos."
        }
      },
      {
        "@type": "Question",
        "name": "¿Cuál es el mejor hosting en Colombia?",

        "acceptedAnswer": {
          "@type": "Answer",
          "text": "En primer lugar, considera el tipo de sitio web que estás construyendo y el tráfico que esperas recibir. Esto te ayudará a determinar si necesitas un plan de hosting compartido, VPS o dedicado. Además, presta atención al rendimiento y la velocidad del servidor. Asegúrate de elegir un proveedor de hosting con servidores confiables y rápidos para garantizar una experiencia óptima para tus usuarios. El soporte técnico es otro aspecto crucial a considerar. Busca un proveedor que ofrezca un soporte técnico eficiente y receptivo, ya sea a través de tickets, correo electrónico o teléfono. Esto te brindará tranquilidad sabiendo que puedes obtener ayuda rápidamente si surge algún problema. No olvides tener en cuenta la seguridad. Es fundamental elegir un proveedor de hosting que ofrezca medidas de seguridad sólidas, como firewalls, certificados SSL y copias de seguridad automáticas, para proteger tu sitio web y los datos de tus usuarios. Finalmente, compara los precios y las características de diferentes proveedores de hosting y elige el que mejor se adapte a tus necesidades y presupuesto. Como empresa de hosting en Colombia, estamos aquí para ayudarte en este proceso y ofrecerte soluciones que impulsen el éxito de tu sitio web. Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos. Estamos aquí para ayudarte a encontrar la mejor opción de hosting para tu proyecto."
        }
      }
        // Otras FAQ...
      ]
    }

    // Datos estructurados para Producto
    const Service = [
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Personal",
        "description": "Ideal para emprendedores y Landing Pages.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "2",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia DirectAdmin",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "79997",  // Precio en pesos colombianos durante la oferta
          "priceValidUntil": "2024-12-11",

          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",

            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",

          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        },
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Corporativo",
        "description": "Ideal para empresas y tiendas virtuales.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "4",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia cPanel",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "119999",  // Precio en pesos colombianos durante la oferta
          "priceValidUntil": "2024-12-11",
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",

            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",

          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        },
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Básico",
        "description": "Ideal para pequeños negocios y sitios personales.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "44",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia DirectAdmin",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "99999",  // Precio en pesos colombianos
          "priceValidUntil": "2024-12-11",
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",

            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",

          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        }
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Empresarial",
        "description": "Ideal para sitios con altos volúmenes de usuarios.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "89",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia cPanel",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "179997",  // Precio en pesos colombianos
          "priceValidUntil": "2024-12-11",
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",

            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",

          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        }
      }
    ]


    const { site } = this.props.data

    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
          <script type="application/ld+json">
            {JSON.stringify(Organizacion)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(QA)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(Service)}
          </script>
        </Helmet>
        <Layout
          isHome={true}
        >
          <Suspense fallback={<div>Cargando...</div>}>
            <Slogan />
            <Vasco />
            <CloudHosting />
            <CloudHosting2 />
            <SslGratis />
            <SSLSan />
            <SSLBlancoInicio />
            <Hola />
            <DeveloperWeb />
            <DeveloperMobile />
            <WebDesign />
            <Conecta />
            <SelloSitelock />
            <SeguridadInformatica />
            <AsesoriaGratis />
            <OtrosServicios />
            <OffShore />
            <TestimonialsThree />
            <ClientsOne />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>

        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`